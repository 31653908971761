import { db } from '@/firebase/config'
import { ref } from "vue"
import { getProfile } from '../Profile/getProfile'

const getPostOppStatus = async (store, oppId) => {
  try {
    await db.collection("Opportunities").doc(oppId).collection("registered").get().then(docs => {
      let result = []
      let profile = ref(null);
      let promises = []
      docs.forEach(doc => {
        promises.push(getProfile(profile, doc.data().uid).then(_ => {
          result.push({ ...profile.value, status: doc.data().status, id: doc.id, uid: doc.data().uid })
        }))
      });
      return Promise.all(promises).then(_ => {store.value = result})
    })
  } catch (error) {
    alert("getPostOppStatus: " + error)
  }
};

export default getPostOppStatus
