import { db } from '@/firebase/config'

const clearRegistration = async (oppId) => {
  try {
    const juniorArr = [];
    await db.collection("Opportunities").doc(oppId).collection("registered").get().then(docs => {
      docs.forEach(doc => {
        juniorArr.push(doc.data().uid);
      })
    })
    var promises = []
    juniorArr.forEach(junior => {
      promises.push(db.collection("Profiles").doc(junior).collection("myOpps").where("oppId", "==", oppId).get().then(docs => {
        var ref = null;
        docs.forEach(doc => {
          ref = doc.ref;
        })
        if (ref != null) {
          return ref.delete();
        }
      }));
    })
    // console.log("clearRegistration activated")
    return Promise.all(promises)
  } catch (error) {
    alert("clearRegistration: " + error)
  }
};

export default clearRegistration
