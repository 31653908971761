import { db } from '@/firebase/config'
import wrapWithCache from '@/composables/wrapWithCache'

const getOpp = async (store, docId) => {
  try {
    await db.collection("Opportunities").doc(docId).get().then(async doc => {
      // console.log("getOpp success")
      let applicantsCount = 0
      let juniorAcceptedCount = 0
      await doc.ref.collection("registered").get().then(docs => {
        docs.forEach(doc => {
          applicantsCount++
          if (doc.data().status == "accepted") {
            juniorAcceptedCount++;
          }
        })
      })
      store.value = { ...doc.data(), id: doc.id, applicantsNum: applicantsCount, acceptedNum: juniorAcceptedCount }
    })
  } catch (error) {
    alert(error)
  }

};

export default wrapWithCache(getOpp, "opp")
