import { db } from '@/firebase/config'
import { analytics } from '../../firebase/config';
const deleteOpp = async (oppId) => {
  try {
    await db.collection("Opportunities").doc(oppId).collection("registered").get().then(docs => {
      var promises = []
      docs.forEach(doc => promises.push(doc.ref.delete()))
      return Promise.all(promises)
    })
    await db.collection("Opportunities").doc(oppId).delete();
    analytics.logEvent("new_opportunity_deleted");
  } catch (error) {
    alert("deleteOpp: " + error)
  }
};

export default deleteOpp
